jQuery(document).ready(function ($) {

//Popup after send form

  // Close on click outside the popup
  $(document).mouseup(function (e) {
    var popup = $('.popup-block');
    if (e.target != popup[0] && popup.has(e.target).length === 0) {
      $('.popup').fadeOut();
    }
  });

  // Close on tne cross - button
  $('.popup-close').click(function () {
    $('.popup').fadeOut();
  });

  // Slick Slider - in block <!-- CUSTOM SCREENS - Slider -->

  $('.custom-screens-slider').slick(({
    centerMode: true,
    centerPadding: '135px',
    infinite: true,
    slidesToShow: 4,
    swipe: true,
    slidesToScroll: 2,
    prevArrow: ('.custom-screens-prev'),
    nextArrow: ('.custom-screens-next'),
     responsive: [
      {
      breakpoint: 900,
      settings: {
        centerMode: false,
        swipe: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerPadding: '0',
      }
    },
    {
      breakpoint: 600,
      settings: {
        arrow: false,
        prevArrow: false,
        nextArrow: false,
        dots: true,
        swipe: true,
        slidesToShow: 1.5,
        centerPadding: '0',
      }
    }
  ]
}));

 //  // Slick Slider - in block <!-- Check screen one - slider -->

  $('.check-screen-one-slider').slick(({
    slidesToShow: 2,
    swipe: true,
    slidesToScroll: 1,
    variableWidth: true,
    prevArrow: ('.check-screen-prev'),
    nextArrow: ('.check-screen-next'),
     responsive: [
    {
      breakpoint: 1050,
      settings: {
        arrow: false,
        prevArrow: false,
        nextArrow: false,
        dots: true,
        swipe: true,
      }
    },
    {
      breakpoint: 361,
      settings: {
        slidesToShow: 1,
        arrow: false,
        prevArrow: false,
        nextArrow: false,
        dots: true,
        swipe: true,
      }
    }
]
}));

  // Slick Slider - in block <!-- Check screen two - slider -->

  $('.check-screen-two-slider').slick(({
    slidesToShow: 2,
    swipe: true,
    slidesToScroll: 1,
    variableWidth: true,
    prevArrow: ('.check-screen-two-prev'),
    nextArrow: ('.check-screen-two-next'),
     responsive: [
    {
      breakpoint: 1050,
      settings: {
        arrow: false,
        prevArrow: false,
        nextArrow: false,
        dots: true,
        swipe: true,
      }
    },
    {
      breakpoint: 361,
      settings: {
        slidesToShow: 1,
        arrow: false,
        prevArrow: false,
        nextArrow: false,
        dots: true,
        swipe: true,
      }
    }
]
}));

  // Slick Slider - in block <!-- Review -->

  $('.review-slider').slick(({
    slidesToShow: 3,
    swipe: true,
    slidesToScroll: 1,
    variableWidth: true,
    prevArrow: ('.review-prev'),
    nextArrow: ('.review-next'),
      responsive: [
    {
      breakpoint: 1100,
      settings: {
        arrow: false,
        prevArrow: false,
        nextArrow: false,
        dots: true,
        swipe: true,
      }
    }
  ]
}))

})